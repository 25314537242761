import {
  FlexEndColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  PreTitle,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import RhombusButton from "./RhombusButton";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";

/**
 * @deprecated
 */

const TrustedPartnerSection = ({
  color = "var(--white)",
  button = true,
  title = "Your Trusted Security Partner",
  preTitle = "Curated, consultative solutions for your BUSINESS",
  blurb = "Best-in-class physical security is just the start. Rhombus is committed to building lasting partnerships, innovating products and services through customer feedback, and quickly rolling out new features that will keep you ahead of the curve.",
  buttonCopy = CTA_BUTTON_COPY,
}) => {
  const imageSrc = "../../images/trusted-partner.png";
  return (
    <SectionContainer style={{ background: color }}>
      <SectionInner style={{ justifyContent: "space-between" }}>
        <SectionCol>
          <StaticImage
            alt="g2 badges for Best Usability, Leader and Best Results for Spring 2023"
            loading="eager"
            src={imageSrc}
            placeholder="blurred"
            style={{ width: "100%" }}
          />
        </SectionCol>
        <FlexEndColumn>
          <TextContainer style={{ maxWidth: "500px" }}>
            <PreTitle color="var(--blue-500)">{preTitle}</PreTitle>
            <TitleMed>{title}</TitleMed>
            <MainParagraph>{blurb}</MainParagraph>
            {button && (
              <RhombusButton
                type="landing"
                title={buttonCopy}
                style={{ marginTop: "0.75rem" }}
              />
            )}
          </TextContainer>
        </FlexEndColumn>
      </SectionInner>
    </SectionContainer>
  );
};

export default TrustedPartnerSection;
